.main_div {
  background-color: rgb(241, 241, 241);
  margin-bottom: -55px;
}

.preview_text {
  padding-top: 10px;
}

.link {
  color: blue !important;
}

.link:hover {
  text-decoration: underline;
  cursor: pointer;
}
