@import "../../styles/variables.scss";

.main_div {
  background-color: #15161e;
  color: #ffffff;
  margin-bottom: 0;
  padding-top: 50px;
  padding-bottom: 120px;
}

.main_div_404 {
  background: #2c2d35;
}

.footer_row {
  padding-left: 5vw;
}

.col_main {
  text-align: left;
}

.section_title {
  font-weight: bold;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 16px;
}

.link_row {
  margin-bottom: 16px;
}

.link_text {
  font-weight: bold;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #a1a2a5;
}

.link_text:hover {
  color: #ffffff;
}

.copyright {
  font-size: 12px;
  line-height: 225%;
  color: #a1a2a5;
  margin-bottom: 40px;
}

.copyright_mobile {
  display: none;
}

.copyright_link {
  color: white;
}

.privacy_link {
  font-weight: bold;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.05em;
  color: #a1a2a5;
}

.privacy_link:hover {
  color: #ffffff;
}

.legal_div {
  text-align: left;
}

.light_theme {
  background: $white;

  .section_title {
    color: $black;
  }

  .link_text {
    color: #979797;
    &:hover {
      color: $black;
    }
  }

  .copyright_link {
    color: #979797;
    &:hover {
      color: $black;
    }
  }
}

@media screen and (max-width: 576px) {
  .footer_row {
    padding-left: 15vw;
  }

  .col_main {
    margin-top: 32px;
  }

  .logo_container {
    text-align: left;
  }

  .section_title {
    font-size: 16px;
    line-height: 34px;
    letter-spacing: 0.05em;
  }

  .link_text {
    font-size: 16px;
    line-height: 34px;
    letter-spacing: 0.05em;
  }

  .copyright {
    font-size: 14px;
    font-weight: 400;
  }

  .privacy_link {
    font-size: 14px;
    font-weight: 400;
  }

  .copyright_desktop {
    display: none;
  }

  .copyright_mobile {
    display: block;
    margin-top: 25px;
    text-align: left;
  }
}
