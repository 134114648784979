@import "/styles/variables.scss";

.main_div {
  font-size: 13px;
  text-align: center;
}

.button_div {
  width: 650px;
  height: 125px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  background: $citrine;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  z-index: $zIndex_banner - 1;
}

.dark_div {
  background-color: #15161e !important;
}

.cta_container {
  margin-left: auto;
  margin-right: auto;
  width: 225px;
  height: 50px;
}

.button {
  -webkit-font-smoothing: antialiased;
  width: 200px;
  margin: auto;
  background-color: $purple;
  color: $citrine;
  font-size: 20px;
  border-color: $purple !important;
  border-radius: 50px;
  font-weight: $fontWeightMedium;
  padding-bottom: 10px;
  padding-top: 10px;
}

.button:hover,
.button:active,
.button:focus {
  background-color: $citrine;
  color: $purple;
}

.button_copy_wrap {
  font-size: 20px;
  color: $purple;
  padding-left: 30px;
  line-height: 22px;
  font-weight: 500;
  text-align: left;
  width: 200px;
}

.image_container {
  margin-left: auto;
  margin-right: auto;
  width: 125px;
  margin-top: 10px;
}

.contactBannerWrapper {
  position: fixed;
  width: 100%;
  z-index: $zIndex_banner;
}

@media screen and (max-width: 768px) {
  .button {
    font-size: $fontSize_2xs;
  }

  .button_div {
    width: 100%;
    height: 100px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    justify-content: center;
  }
}

@media screen and (max-width: 576px) {
  .button_copy_wrap {
    font-size: 16px;
    padding-left: 20px;
    line-height: 18px;
    font-weight: 600;
    width: 34%;
  }

  .image_container {
    width: 20%;
  }

  .button {
    width: 80%;
  }

  .cta_container {
    width: 46%;
  }
}

@media screen and (max-width: 420px) {
  .button_copy_wrap {
    font-size: 13px;
  }
}
